import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '@components/layout/Layout'
import LearnMoreComponent from '@components/learn-more/learn-more.component'
import SiteLink from '@components/SiteLink'
import ReferenceComponent from '@components/references/reference.component'
import ReadNext from '@components/read-next-button/read-next-button'

import './epworth-sleepiness-scale-for-children.scss'

const learnMoreContent = {
  className: 'learn-more-wrapper-gray',
  title: 'Screen your pediatric patient for narcolepsy',
  link: '/pediatric-patients/screening/',
  linkLabel: 'ESS-CHAD',
  tracking: 'ess-chad info, click, narcolepsy symtom screener - ess chad'
}

const references = [
  {
    content:
      'Wang YG, Benmedjahed K, Lambert J, et al. Assessing narcolepsy with cataplexy in children and adolescents: development of a cataplexy diary and the ESS-CHAD.',
    source: 'Nat Sci Sleep. ',
    info: '2017;9:201-211.'
  },
  {
    content:
      "Janssen KC, Phillipson S, O'Connor J, Johns MW. Validation of the Epworth Sleepiness Scale for Children and Adolescents using Rasch analysis.",
    source: 'Sleep Med. ',
    info: '2017;33:30-35. '
  }
]

class ScaleForChildrenComponent extends Component {
  render() {
    const readNext = {
      readNextContent: 'Patient/Caregiver Perspectives',
      goTo: '/pediatric-patients/patient-and-caregiver-perspectives/',
      tracking: ''
    }
    return (
      <Layout addedClass="page-epworth-sleepiness-scale-for-children" location={this.props.location}>
        <div className="primary-container header-section">
          <div className="title">
            <SiteLink to="/pediatric-patients/screening/" className="ess-chad-arrow-align">
              <FontAwesomeIcon className="arrow-left" icon="angle-left" />
            </SiteLink>
            <h1 className="main-page-header">Epworth Sleepiness Scale for Children and Adolescents</h1>
          </div>
          <LearnMoreComponent learnMoreContent={learnMoreContent} />
        </div>
        <div className="section-gradient" />
        <div className="primary-container description-section">
          <div className="sub-header-section">
            <h2 className="sub-header-title">ESS-CHAD</h2>
            <SiteLink
              className="sub-header-link"
              to="/resources/resource-center/"
              >
              Download screening tools
            </SiteLink>
          </div>
          <p className="page-description">
            The Epworth Sleepiness Scale for Children and Adolescents (ESS-CHAD) is used to assess the level of
            excessive daytime sleepiness in children and adolescents.<sup>1,2</sup> The ESS-CHAD is a modified version
            of the{' '}
            <SiteLink tracking="ess-chad info, click, screening for adults - ess chad" to="/screening-and-diagnosis/screeners/">
              ESS
            </SiteLink>{' '}
            that has been validated as a reliable measure of sleepiness in patients 12 to 18 years of age.<sup>1,2</sup>
          </p>
          <p className="page-description">
            Minor changes were made to the instructions and descriptions of some activities on the ESS to improve
            relatability and comprehension of the ESS-CHAD by children and adolescents.<sup>1</sup>
          </p>

          <ReadNext readNext={readNext} />
        </div>
        <ReferenceComponent referenceList={references} />

      </Layout>
    )
  }
}

export default ScaleForChildrenComponent
